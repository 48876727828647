<template>
  <div class="staggerXBar" :ref="ref"></div>
</template>

<script>
/*
 * seriesData要加工
 * 小于0的
 * 要加柱状图颜色和字体颜色
 * */

import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  BarChart,
  GridComponent,
  TitleComponent,
  LegendComponent,
  CanvasRenderer,
]);
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);

import { nextTick } from "vue";

export default {
  name: "staggerXBar",
  data() {
    return {
      myChart: "",
    };
  },
  props: {
    seriesData: {
      type: Array,
      default() {
        // 大数组的长度表示几项数据，item数组长度表示平台数量
        return [
          [10, 20, 30, 40, null],
          [30, 30, 25, 30, 40],
          [20, 40, 10, 5, 20],
          [40, 10, 35, 25, 40],
        ];
      },
    },
    xAxisData: {
      type: Array,
      default() {
        return ["一线城市", "二线城市", "三线城市", "四线城市"];
      },
    },
    yAxisData: {
      type: Array,
      default() {
        return ["微信", "微博", "抖音", "小红书", "哔哩哔哩"];
      },
    },
    title: {
      type: String,
      default: "性别占比",
    },
    color: {
      type: Array,
      default() {
        return [
          "#4260AF",
          "#8396C4",
          "#544167",
          "#0E8A92",
          "#D1AB71",
          "#E2CFB4",
          "#76A4EA",
        ];
      },
    },
    ref: {
      type: String,
      default: "staggerXBar",
    },
  },
  computed: {
    formatSeries() {
      let formatSeries = [];
      const len = this.seriesData.length;
      for (let [index, value] of this.seriesData.entries()) {
        // 加工label
        let label = {
          show: true,
          fontSize: 12,
          formatter: (param) => {
            if (!param.value) {
              return "";
            } else {
              return param.value + "%";
            }
          },
        };
        // 加圆角的情况
        // 对特别小对值进行样式加工
        value = value.map((val, i) => {
          let borderRadius = [0, 0, 0, 0];
          if (index === 0) {
            borderRadius = [10, 0, 0, 10];
          }
          if (index === len - 1) {
            borderRadius = [0, 10, 10, 0];
          }
          if (!val) {
            return {
              value: val,
            };
          } else if (val < 5 && val > 0) {
            return {
              value: val,
              label: {
                position: "bottom",
                backgroundColor: this.color[index],
                color: "#fff",
                distance: -2,
                padding: 2,
              },
              itemStyle: {
                borderRadius,
              },
            };
          } else if (val === 100) {
            return {
              value: val,
              itemStyle: {
                borderRadius: [10, 10, 10, 10],
              },
            };
          } else {
            borderRadius = [0, 0, 0, 0];
            let tempArr1 = [];
            for (let j = 0; j < index; j++) {
              this.seriesData[j][i] != null && this.seriesData[j][i] !== 0
                ? tempArr1.push(this.seriesData[j][i])
                : "";
            }
            if (!tempArr1.length) {
              borderRadius[0] = 10;
              borderRadius[3] = 10;
            }
            tempArr1 = [];
            for (let j = index + 1; j < len; j++) {
              this.seriesData[j][i] != null && this.seriesData[j][i] !== 0
                ? tempArr1.push(this.seriesData[j][i])
                : "";
            }
            if (!tempArr1.length) {
              borderRadius[1] = 10;
              borderRadius[2] = 10;
            }
            return {
              value: val,
              itemStyle: {
                borderRadius,
              },
            };
          }
        });
        const tempObj = {
          type: "bar",
          stack: "总量",
          barWidth: 22,
          silent: true,
          name: this.xAxisData[index],
          data: value,
          label,
          showBackground: true,
          backgroundStyle: {
            color: "#ccc",
            borderRadius: 10,
          },
        };
        formatSeries.push(tempObj);
      }
      return formatSeries;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.myChart.resize();
      }, 500);
    });
    nextTick(() => {
      this.myChart = echarts.init(this.$refs[this.ref]);
      this.drawStaggerXBar();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    drawStaggerXBar() {
      this.myChart.clear();
      unwarp(this.myChart).setOption(
        {
          grid: {
            show: false,
            top: 50,
            right: 10,
            bottom: 10,
            left: 38,
          },
          title: {
            text: this.title,
            textStyle: {
              fontWeight: "normal",
              fontSize: 20,
              fontFamily: "Microsoft YaHei",
              color: "#4f4f4f",
            },
            left: -5,
            top: 0,
          },
          legend: {
            top: 4,
            left: 140,
            bottom: 0,
            itemGap: 18,
            itemWidth: 13,
            itemHeight: 13,
            data: this.xAxisData,
            icon: "circle",
            textStyle: {
              color: "#666",
            },
            selectedMode: false,
          },
          xAxis: {
            type: "value",
            show: false,
          },
          yAxis: {
            inverse: true,
            type: "category",
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              align: "right",
              margin: 14,
              formatter: (value) => `{${value}| }`,
              rich: {
                wechat: {
                  fontSize: 21,
                  width: 21,
                  height: 21,
                  backgroundColor: {
                    image: require("./platformImg/wechat.png"),
                  },
                },
                weibo: {
                  fontSize: 21,
                  width: 21,
                  height: 21,
                  backgroundColor: {
                    image: require("./platformImg/weibo.png"),
                  },
                },
                douyin: {
                  fontSize: 21,
                  width: 21,
                  height: 21,
                  backgroundColor: {
                    image: require("./platformImg/douyin.png"),
                  },
                },
                xiaohongshu: {
                  fontSize: 21,
                  width: 21,
                  height: 21,
                  backgroundColor: {
                    image: require("./platformImg/xiaohongshu.png"),
                  },
                },
                bilibili: {
                  fontSize: 21,
                  width: 21,
                  height: 21,
                  backgroundColor: {
                    image: require("./platformImg/bilibili.png"),
                  },
                },
                kuaishou: {
                  fontSize: 21,
                  width: 21,
                  height: 21,
                  backgroundColor: {
                    image: require("./platformImg/kuaishou.png"),
                  },
                },
              },
            },
            data: this.yAxisData,
          },
          series: this.formatSeries,
          textStyle: {
            fontFamily: "Microsoft YaHei",
            fontSize: 11,
          },
          color: this.color,
        },
        true
      );

      this.resize();
    },
    resize() {
      window.resize && window.resize();
    },
  },
  watch: {
    seriesData: {
      // watch不到数据变化。。。
      handler() {
        this.drawStaggerXBar();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.staggerXBar {
  width: 100%;
  height: 100%;
}
</style>
